import '../styles/blog.css'

import * as React from 'react'

import { navigate } from 'gatsby'

const BdayBump = () => {
  React.useEffect(() => {
    setTimeout(() => {
      navigate('/blog/2020-06-25-a-moment-for-my-birthday', { replace: true })
    }, 100)
  }, [])
  return <span></span>
}
export default BdayBump
